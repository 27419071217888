import { isEmpty } from 'lodash';
import { upgradeReferralClick } from '@wix/bi-logger-membership/v2';
import { TPA_EXPERIMENTS } from '@wix/pricing-plans-common/experiments';
import { CheckoutPublicData } from '@wix/pricing-plans-router-utils';
import type { CreateControllerFn, ControllerParams } from '@wix/yoshi-flow-editor';
import { demoOrderFixture, demoPlanFixture } from '../../fixtures';
import { createBenefitsApi, createOrdersApi, createPlansApi, createPremiumApi } from '../../services';
import { Analytics } from '../../services/analytics';
import { commonBIDataFromFlowAPI } from '../../services/commonBIData';
import { createPaymentMethodsApi } from '../../services/payment-methods';
import { createSettingsApi } from '../../services/settings';
import { WarmupData } from '../../services/WarmupData';
import { MessageCode, noModal } from '../../types/common';
import { resolveLocale } from '../../utils';
import {
  createOnLoginHandlerWithTB8465Workaround,
  TB8465_createOnLoginHandlerWithRetry,
} from '../../utils/createOnLoginHandlerWithTB8465Workaround';
import { errorToMessage, toError } from '../../utils/errors';
import { hasMultiplePages } from '../../utils/multiple-pages';
import { getUserData } from '../../utils/user';
import { CheckoutController } from '../PackagePicker/controller/CheckoutController';
import { Navigation } from '../PackagePicker/controller/Navigation';
import { Router } from '../PackagePicker/controller/Router';
import { CheckoutWidgetProps } from '../PackagePicker/Widget/Checkout';

const createController: CreateControllerFn = async ({ flowAPI }: ControllerParams) => {
  const { setProps: untypedSetProps, wixCodeApi, appParams, compId, config } = flowAPI.controllerConfig;

  const noop = () => {};
  const setProps: (props: Partial<CheckoutWidgetProps>) => void = untypedSetProps;

  const plansApi = createPlansApi(flowAPI.httpClient);
  const premiumApi = createPremiumApi(flowAPI.httpClient);
  const ordersApi = createOrdersApi(flowAPI.httpClient);
  const benefitsApi = createBenefitsApi(flowAPI.httpClient);
  const settingsApi = createSettingsApi(flowAPI.httpClient);
  const paymentMethodsApi = createPaymentMethodsApi(flowAPI.httpClient, appParams.instanceId);
  const warmupData = new WarmupData(compId, wixCodeApi, flowAPI);
  flowAPI.bi?.updateDefaults(commonBIDataFromFlowAPI(flowAPI));
  const nav = new Navigation(
    flowAPI,
    appParams.appDefinitionId,
    flowAPI.experiments.enabled(TPA_EXPERIMENTS.INSTALL_SPLIT_PAGES) && (await hasMultiplePages(wixCodeApi)),
  );
  const analytics = new Analytics(wixCodeApi.window);
  const router = new Router(setProps, nav, analytics, wixCodeApi, flowAPI);
  const checkout = new CheckoutController(
    setProps,
    wixCodeApi,
    router,
    flowAPI,
    plansApi,
    ordersApi,
    benefitsApi,
    premiumApi,
    paymentMethodsApi,
    analytics,
    settingsApi,
    warmupData,
  );

  return {
    async pageReady() {
      wixCodeApi.seo.renderSEOTags({ itemType: 'PAYMENT_PAGE', itemData: {} });

      const data = wixCodeApi.window.getRouterPublicData<CheckoutPublicData>();

      setProps({
        user: getUserData(wixCodeApi.user.currentUser),
        biCheckoutStage: noop,
        integrationData: {},
        appInstanceId: appParams.instanceId,
        siteOwnerId: flowAPI.controllerConfig.platformAPIs.bi?.ownerId,
        visitorId: flowAPI.controllerConfig.platformAPIs.bi?.visitorId,
        metaSiteId: flowAPI.controllerConfig.platformAPIs.bi?.metaSiteId,
        instance: appParams.instance,
        benefits: [],
        selectedPlan: {},
        modal: noModal,
        fitToContentHeight: true,
        hideToast: () => setProps({ message: undefined }),
        showToast: (message: MessageCode) => setProps({ message }),
        biUpgradeReferralClick: (referralInfo: string) =>
          flowAPI.bi?.report(upgradeReferralClick({ referralInfo, isPreview: false })),
        locale: resolveLocale(wixCodeApi),
      });

      if (data && !isEmpty(data)) {
        const { checkoutData, plan, order } = data;

        wixCodeApi.site.onInstanceChanged(({ instance: newInstance }) => {
          setProps({ instance: newInstance });
        }, appParams.appDefinitionId);

        if (flowAPI.experiments.enabled(TPA_EXPERIMENTS.ON_LOGIN_RETRY_FOR_SAFARI)) {
          TB8465_createOnLoginHandlerWithRetry(wixCodeApi, (user) => checkout.initializeUser?.(user));
        } else {
          createOnLoginHandlerWithTB8465Workaround(wixCodeApi)((user) => checkout.initializeUser?.(user));
        }

        if (!plan) {
          await router.gotoList(checkoutData.integrationData, MessageCode.PLAN_NOT_FOUND);
        } else {
          try {
            await checkout.update(plan, checkoutData.integrationData, order, checkoutData.guestCheckoutEnabled);
          } catch (e) {
            setProps({ message: errorToMessage(toError(e)), isCheckoutDataInitialized: true });
          }
        }
      } else {
        const [plan] = await plansApi.query();
        const { environment, translations } = flowAPI;
        if (environment.isViewer && plan === undefined) {
          await router.gotoList({}, MessageCode.PLAN_NOT_FOUND);
        } else {
          const selectedPlan = plan ?? config.publicData?.APP?.demoData?.plans?.[0] ?? demoPlanFixture(translations.t);
          const order = plan ? undefined : demoOrderFixture(translations.t);
          await checkout.update(selectedPlan, {}, order);
        }
      }
    },
    async updateConfig(_$w, newConfig) {},
  };
};

export default createController;
